import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CapitalizePipe } from '@z-trippete/angular-pipes';

import { emitMobileAppEvent } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private capitalize = new CapitalizePipe();

  constructor(private title: Title, private translate: TranslateService) {}

  public set(title?: string) {
    if (!title) {
      this.defaultTitle();
      return;
    }

    const appName = this.capitalize.transform(
      this.translate.instant('app_name'),
    );

    emitMobileAppEvent('setWebViewTitle', title);

    this.title.setTitle(`${title} | ${appName}`);
  }

  public defaultTitle() {
    const defaultTitle = `${this.capitalize.transform(
      this.translate.instant('app_name'),
    )}`;

    this.title.setTitle(defaultTitle);
  }
}
